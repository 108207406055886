const CONSTANT = {
  COOKIE: {
    APP_VERSION: 'app-version',
    CART_HREF: 'cart-href',
  },
  HEADERS: {
    HEADER_APP_VERSION: 'X-LG-APP-VERSION',
    HEADER_ACCESS_TOKEN: 'X-LG-ACCESS-TOKEN',
    HEADER_ACCESS_TOKEN_LOWERCASE: 'x-lg-access-token',
    HEADER_CLIENT_TYPE: 'X-LG-CLIENT-TYPE',
  },
  APP_VERSION: {
    ANDROID: {
      CURRENT: '1.6.0',
      NEW: '1.7.0',
    },
  },
  ETC: {
    USER_REGION_TYPE_CODE: 'USER_REGION_TYPE_CODE',
  },
};

export default CONSTANT;

export const PREMIUM_BRAND_LIST = [
  {
    id: 'HERMES',
    name: 'HERMES',
    nameKor: '에르메스',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/hermes.png',
  },
  {
    id: 'CHANEL',
    name: 'chanel',
    nameKor: '샤넬',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/chanel.png',
  },
  {
    id: 'LOUIS_VUITTON',
    name: 'louis-vuitton',
    nameKor: '루이비통',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/louis-vuitton.png',
  },
  {
    id: 'GUCCI',
    name: 'gucci',
    nameKor: '구찌',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/gucci.png',
  },
  {
    id: 'PRADA',
    name: 'prada',
    nameKor: '프라다',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/prada.png',
  },
  {
    id: 'BURBERRY',
    name: 'burberry',
    nameKor: '버버리',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/burbuerry.png',
  },
  {
    id: 'CELINE',
    name: 'celine',
    nameKor: '셀린',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/celine.png',
  },
  {
    id: 'MONCLER',
    name: 'moncler',
    nameKor: '몽클레르',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/moncler.png',
  },
  {
    id: 'VALENTINO',
    name: 'valentino',
    nameKor: '발렌티노',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/valentino.png',
  },
  {
    id: 'FENDI',
    name: 'fendi',
    nameKor: '펜디',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/fendi.png',
  },
  {
    id: 'DIOR',
    name: 'dior',
    nameKor: '디올',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/dior.png',
  },
  {
    id: 'LOROPIANA',
    name: 'lolopiana',
    nameKor: '로로 피아나',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/lolopiana.png',
  },
  {
    id: 'MIUMIU',
    name: 'miumiu',
    nameKor: '미우미우',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/miumiu.png',
  },
  {
    id: 'KITON',
    name: 'kiton',
    nameKor: '키톤',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/kiton.png',
  },
  {
    id: 'BRIONY',
    name: 'briony',
    nameKor: '브리오니',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/briony.png',
  },
  {
    id: 'STEFANO_RICCI',
    name: 'stefano-ricci',
    nameKor: '스테파노 리치',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/stefano-ricci.png',
  },
  {
    id: 'BOTTEGA_VENETA',
    name: 'bottega-veneta',
    nameKor: '보테가 베네타',
    logo: 'https://static.laundrygo.com/images/webview/guide/fee/bottega-veneta.png',
  },
];

export const HEX_OPACITY_SUFFIX = {
  0: '00',
  5: '0C',
  10: '19',
  15: '26',
  20: '33',
  25: '3F',
  30: '4C',
  35: '59',
  40: '66',
  45: '72',
  50: '7F',
  55: '8C',
  60: '99',
  65: 'A5',
  70: 'B2',
  75: 'BF',
  80: 'CC',
  85: 'D8',
  90: 'E5',
  95: 'F2',
  100: 'FF',
};

export const INTL_DEFAULT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
  timeZone: 'Asia/Seoul',
};

export const NON_AUTHENTICATION_API_LIST = [
  //   {
  //     method: 'get',
  //     url: '/v2/products/categories',
  //     exact: true,
  //   },
  //   {
  //     method: 'get',
  //     url: '/v2/products?',
  //     exact: false,
  //   },
  //   {
  //     method: 'get',
  //     url: '/v2/products/:productId',
  //     exact: false,
  //   },
  {
    method: 'get',
    url: '/v2/products/recommend',
    exact: true,
  },
  //   {
  //     method: 'get',
  //     url: '/v2/products/validation?',
  //     exact: false,
  //   },
  {
    method: 'get',
    url: '/v2/notices',
    exact: false,
  },
];

export const AUTHENTICATION_API_URL_LIST = ['/v2/products/basket', '/v2/products/order'];
